
import { io } from 'socket.io-client'

    export const socket = io("wss://echo.in-arthurs-apps.space", {
    path: '/sio/chat',
    transports: ["websocket"],
})

function App() {
  return (
    <div className="App">
    </div>
  );
}

export default App;
